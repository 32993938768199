import { fetchAuthSession } from 'aws-amplify/auth'

export const getToken = async () => {
  return (await fetchAuthSession()).tokens?.idToken?.toString()
}

class TokenHelper {
  super() {
    this.session = null
  }

  async preWarm() {
    await this.updateSession()
  }

  async updateSession() {
    const session = await fetchAuthSession()
    this.session = session
    setTimeout(
      this.updateSession.bind(this),
      session.tokens.idToken.payload.exp * 1000 - Date.now() - 10000
    )
  }

  getToken() {
    const { session, updateSession } = this
    if (!session) {
      updateSession()
      return null
    }
    return session.tokens?.idToken?.toString()
  }
}

export const tokenHelper = new TokenHelper()
