import { Amplify } from 'aws-amplify';
import {
  signIn,
  confirmSignIn,
  signOut,
  signUp,
  confirmSignUp,
  resetPassword as resetPasswordBase,
  confirmResetPassword,
  updatePassword,
  fetchAuthSession,
  getCurrentUser
} from 'aws-amplify/auth';
import { tokenHelper } from '../../externalMethods/tokenHelper'

const region = process.env.REACT_APP_COGNITO_AUTH_REGION
const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID
const userPoolWebClientId = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID
const authenticationFlowType = process.env.REACT_APP_COGNITO_AUTH_FLOW_TYPE

Amplify.configure({
  Auth: {
    Cognito: {
      region: region,
      userPoolId: userPoolId,
      userPoolClientId: userPoolWebClientId
    }
  }
})

const SET_USER = '@auth/set-user'
const SET_CREDENTIALS = '@auth/set-credentials'
const SET_LOADING = '@auth/set-loading'
const SET_RESTORING = '@auth/set-restoring'
const SET_ERROR = '@auth/set-error'

const initialState = {
  user: null,
  credentials: null,
  loading: false,
  restoring: true,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return { ...state, user: action.user }
    }
    case SET_CREDENTIALS: {
      return { ...state, credentials: action.credentials }
    }
    case SET_LOADING: {
      return { ...state, loading: action.loading }
    }
    case SET_RESTORING: {
      return { ...state, restoring: action.restoring }
    }
    case SET_ERROR: {
      return { ...state, error: action.error }
    }
    default:
      return state
  }
}

const setUser = user => ({ type: SET_USER, user })

const setCredentials = credentials => ({ type: SET_CREDENTIALS, credentials })

const setLoading = loading => ({ type: SET_LOADING, loading })

const setRestoring = restoring => ({ type: SET_RESTORING, restoring })

const setError = error => ({ type: SET_ERROR, error })

const getAuthenticatedUser = async () => {
  const { username, signInDetails } = await getCurrentUser()
  const session = await fetchAuthSession()

  return {
    username,
    session,
    authenticationFlowType: signInDetails.authFlowType
  }
}

export const restore = () => async dispatch => {
  try {
    const user = await getAuthenticatedUser()
    dispatch(setUser(user))
    await tokenHelper.preWarm()
  } catch (e) {
    // do nothing
  } finally {
    dispatch(setRestoring(false))
  }
}

export const login = (username, password) => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    await signIn({ username, password })
    const user = await getAuthenticatedUser()
    dispatch(setUser(user))
    await tokenHelper.preWarm()
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(setLoading(false))
  }
}

export const logout = message => async () => {
  try {
    if (message) localStorage.setItem('AUTH_NOTIFICATION', message)
    await signOut()
    window.location.reload()
  } catch (e) {
    /* do nothing */
  }
}

export const register = (username, password) => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    await signUp({ username, password })
    const credentials = { username, password }
    dispatch(setCredentials(credentials))
  } catch (e) {
    dispatch(setError(e))
    throw e
  } finally {
    dispatch(setLoading(false))
  }
}

export const verify = (username, code) => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    await confirmSignUp({ username, confirmationCode: code })
  } catch (e) {
    dispatch(setError(e))
    throw e
  } finally {
    dispatch(setLoading(false))
  }
}

export const forgotPasswordRequest = username => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    await resetPasswordBase({ username })
  } catch (e) {
    dispatch(setError(e))
    throw e
  } finally {
    dispatch(setLoading(false))
  }
}

export const forgotPasswordSubmit = (username, code, newPassword) => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    await confirmResetPassword({ username, confirmationCode: code, newPassword })
  } catch (e) {
    dispatch(setError(e))
    throw e
  } finally {
    dispatch(setLoading(false))
  }
}

export const changePassword = (username, oldPassword, newPassword) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    const { user } = getState().auth
    if (user.attributes.email !== username) return
    await updatePassword({ oldPassword, newPassword })
    await signOut({ global: true })
    await signIn({ username, password: newPassword })
    window.location.replace('/')
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(setLoading(false))
  }
}

export const resetPassword = newPassword => async (dispatch, getState) => {
  dispatch(setLoading(true))
  dispatch(setError(null))
  try {
    await confirmSignIn({ challengeResponse: newPassword })
  } catch (e) {
    dispatch(setError(e))
    throw e
  } finally {
    dispatch(setLoading(false))
  }
}

export const clearError = () => setError(null)
