import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as turf from '@turf/turf'
import { RasterSource, RasterLayer, CHMLayer, LayerControl } from '@project/mapbox'
import { Control } from './partials/Control'

const API = process.env.REACT_APP_FORESTRY_API

const ATTRIBUTION = {
  NLS: 'Contains data from the National Land Survey of Finland Topographic Database 06/2014',
  OSM: '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
}

const finlandBoundingBox = [20.6455928891, 59.846373196, 31.5160921567, 70.1641930203]

const getBaseMapSourceUrl = isInFinland => {
  if (isInFinland) return 'https://kapsi-tiles.y2k.fi/tiles/peruskartta/webmercator/{z}/{x}/{y}.jpg'
  return 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
}

const getBaseMapAttribution = isInFinland => {
  if (isInFinland) return ATTRIBUTION.NLS
  return ATTRIBUTION.OSM
}

const getItems = (chmTilePath, aerialTilePath, isInFinland, visible) => {
  const visibility = visible ? 'visible' : 'none'
  return [
    {
      iconClassName: 'fa-map',
      titleKey: 'mapbox:tileLayer.baseMap',
      render: () => (
        <RasterSource url={getBaseMapSourceUrl(isInFinland)} attribution={getBaseMapAttribution(isInFinland)}>
          <RasterLayer
            rasterBrightnessMin={isInFinland ? 0.75 : 0.5}
            rasterSaturation={-1}
            visibility={visibility}
            dynamicLayout
          />
        </RasterSource>
      ),
    },
    {
      iconClassName: 'fa-map',
      titleKey: 'mapbox:tileLayer.baseMap',
      render: () => (
        <RasterSource url={getBaseMapSourceUrl(isInFinland)} attribution={getBaseMapAttribution(isInFinland)}>
          <RasterLayer visibility={visibility} dynamicLayout />
        </RasterSource>
      ),
    },
    {
      iconClassName: 'fa-plane',
      titleKey: 'mapbox:tileLayer.aerialMap',
      render: () => (
        <RasterSource url="https://kapsi-tiles.y2k.fi/tiles/ortokuva/webmercator/{z}/{x}/{y}.jpg" attribution={ATTRIBUTION.NLS}>
          <RasterLayer visibility={visibility} dynamicLayout />
        </RasterSource>
      ),
    },
    chmTilePath
      ? {
          iconClassName: 'fa-plane',
          titleKey: 'mapbox:tileLayer.preforCHMMap',
          render: () => (
            <RasterSource url={`${API}${chmTilePath}`} scheme="tms">
              {visible && <CHMLayer />}
            </RasterSource>
          ),
        }
      : null,
    aerialTilePath
      ? {
          iconClassName: 'fa-plane',
          titleKey: 'mapbox:tileLayer.preforAerialMap',
          render: () => (
            <RasterSource url={`${API}${aerialTilePath}`} scheme="tms">
              <RasterLayer visibility={visibility} dynamicLayout />
            </RasterSource>
          ),
        }
      : null,
  ].filter(i => !!i)
}

export const LAYERS = {
  BASE_GRAYSCALE: 'base-grayscale',
  BASE_COLORED: 'base-colored',
  AERIAL: 'aerial',
  PREFOR_CHM: 'prefor-chm',
  PREFOR_AERIAL: 'prefor-aerial',
}

export const TilesSubstrate = ({
  chmTilePath,
  aerialTilePath,
  geoJSON,
  defaultLayer = LAYERS.BASE_GRAYSCALE,
  enableControl = true,
}) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(true)
  const [activeIndex, setActiveIndex] = useState(Object.values(LAYERS).findIndex(i => i === defaultLayer))

  const isInFinland = useMemo(() => {
    if (!geoJSON) return false
    const bbox = geoJSON.bbox || turf.bbox(geoJSON)
    return turf.booleanWithin(turf.bboxPolygon(bbox), turf.bboxPolygon(finlandBoundingBox))
  }, [geoJSON])

  const items = useMemo(
    () => getItems(chmTilePath, aerialTilePath, isInFinland, visible),
    [chmTilePath, aerialTilePath, isInFinland, visible],
  )
  const item = items[activeIndex]

  return (
    <>
      <React.Fragment key={activeIndex}>{item.render()}</React.Fragment>
      <Control
        onPrevClick={() => setActiveIndex(activeIndex === 0 ? items.length - 1 : activeIndex - 1)}
        onNextClick={() => setActiveIndex(activeIndex >= items.length - 1 ? 0 : activeIndex + 1)}
        iconClassName={item.iconClassName}
        titleKey={item.titleKey}
      />
      {enableControl && (
        <LayerControl
          title={t('mapbox:control.showBaseMap')}
          visible={visible}
          setVisible={setVisible}
          orderIndex={0}
        />
      )}
    </>
  )
}
