import React, { useEffect } from 'react'
import { compose } from 'redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PageHeader, PageContent, Tab, Tabs, ErrorBoundary } from '@project/components'
import { withStore } from '@project/redux'
import { withRouter } from '@project/routing'
import { withAppTheme } from '@project/theme'
import { withAuth, getUser, logout } from '@project/auth'
import { ProjectsPage } from './components/pages/ProjectsPage'
import { PipelinesPage } from './components/pages/PipelinesPage'
import { ParcelsPage } from './components/pages/ParcelsPage'
import { CustomersPage } from './components/pages/CustomersPage'
import { OrdersPage } from './components/pages/OrdersPage'
import { UsersPage } from './components/pages/UsersPage'
import style from './App.module.scss'

const App = () => {
  const { t } = useTranslation()

  const allowed = getUser().session?.tokens.idToken.payload['custom:orderbook'] === 'true'

  useEffect(() => {
    if (!allowed) logout(t('auth.noAccessToOrderbook'))
  }, [allowed, t])

  if (!allowed) return null

  return (
    <ErrorBoundary>
      <PageHeader />
      <PageContent>
        <Tabs>
          <Tab to="projects" label={t('navigation.projects')} />
          <Tab to="parcels" label={t('navigation.parcels')} />
          <Tab to="customers" label={t('navigation.customers')} />
          <Tab to="orders" label={t('navigation.orders')} />
          <Tab to="users" label={t('navigation.plannerUsers')} />
        </Tabs>
        <div className={style['tab-content']}>
          <Routes>
            <Route path="projects/*" element={<ProjectsPage />} />
            <Route path="pipelines/:projectId/*" element={<PipelinesPage />} />
            <Route path="parcels/*" element={<ParcelsPage />} />
            <Route path="customers/*" element={<CustomersPage />} />
            <Route path="orders/*" element={<OrdersPage />} />
            <Route path="users/*" element={<UsersPage />} />
            <Route path="*" element={<Navigate to="projects" />} />
          </Routes>
        </div>
      </PageContent>
    </ErrorBoundary>
  )
}

export default compose(withAppTheme, withRouter, withStore, withAuth)(App)
