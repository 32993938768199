import * as wkt from 'wellknown'
import * as turf from '@turf/turf'
import { authorizedFetch as defaultAuthorizedFetch, parseTrunks } from '@project/lib'
import { pushNotification } from '@project/notifications'

const API = process.env.REACT_APP_FORESTRY_API

const authorizedFetch = async (url, options) => {
  const response = await defaultAuthorizedFetch(url, options)
  await checkForGraphqlError(response.clone())
  return response
}

export const checkForGraphqlError = async response => {
  try {
    const { message, success } = await response.json()
    if ((!response.ok || success === false) && message) pushNotification({ message, type: 'error' })
  } catch (e) {
    // do nothing
  }
}

export const getCustomers = async () => {
  const url = `${API}/customers`
  const response = await authorizedFetch(url)
  return response.json()
}

export const createCustomer = async (name, phone, email) => {
  const url = `${API}/create-customer`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, phone, email }),
  }
  return authorizedFetch(url, options)
}

export const updateCustomer = async (customerId, name, phone, email) => {
  const url = `${API}/update-customer`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ customerId, name, phone, email }),
  }
  return authorizedFetch(url, options)
}

export const getOrders = async () => {
  const url = `${API}/orders`
  const response = await authorizedFetch(url)
  return response.json()
}

export const createOrder = async (customerId, description) => {
  const url = `${API}/create-order`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ customerId, description }),
  }
  return authorizedFetch(url, options)
}

export const updateOrder = async (orderId, customerId, status, description) => {
  const url = `${API}/update-order`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ orderId, customerId, status, description }),
  }
  return authorizedFetch(url, options)
}

export const getProjectPlans = async projectId => {
  const url = `${API}/projects/${projectId}/plans`
  const response = await authorizedFetch(url)
  return response.json()
}

export const getProjects = async () => {
  const url = `${API}/projects`
  const response = await authorizedFetch(url)
  return response.json()
}

export const getParcels = async () => {
  const url = `${API}/parcels`
  const response = await authorizedFetch(url)
  const parcels = await response.json()
  parcels.forEach(p => {
    p.geometry = wkt.parse(p.geometry)
    p.geometry.bbox = turf.bbox(p.geometry)
  })
  return parcels
}

export const createParcel = async (propertyId, parcelNumber, geometryWkt, source) => {
  const url = `${API}/create-parcel`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ propertyId, parcelNumber, geometryWkt, source }),
  }
  return authorizedFetch(url, options)
}

export const updateParcel = async (propertyId, parcelNumber, geometryWkt, source) => {
  const url = `${API}/update-parcel`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ propertyId, parcelNumber, geometryWkt, source }),
  }
  return authorizedFetch(url, options)
}

export const updateProject = async (
  projectId,
  crs,
  firstScanDate,
  orderNumber,
  plannedUavScans,
  plannedBackpackScans,
  notes,
) => {
  const url = `${API}/update-project`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      projectId,
      crs,
      firstScanDate,
      orderNumber,
      plannedUavScans: +plannedUavScans,
      plannedBackpackScans: +plannedBackpackScans,
      notes,
    }),
  }
  return authorizedFetch(url, options)
}

export const getPipelines = async projectId => {
  const url = `${API}/projects/${projectId}/pipelines`
  const response = await authorizedFetch(url)
  return response.json()
}

export const createJob = async mutation => {
  const url = `${API}/create-job`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ mutation }),
  }
  return authorizedFetch(url, options)
}

export const createProject = async (propertyId, parcelNumber, orderNumber, firstScanDate, crs) => {
  const url = `${API}/projects`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ propertyId, parcelNumber, orderNumber, firstScanDate, crs }),
  }
  return authorizedFetch(url, options)
}

export const getTrunksGeohashes = async (propertyId, jobId) => {
  if (!propertyId || !jobId) return undefined
  const url = `${API}/properties/${propertyId}/jobs/${jobId}/trunks-geohashes`
  const response = await authorizedFetch(url)
  return response.json()
}

export const getTrunks = async (propertyId, jobId) => {
  if (!propertyId || !jobId) return undefined
  const geohashes = await getTrunksGeohashes(propertyId, jobId)
  const trunks = []
  await Promise.all(
    geohashes.map(async geohash => {
      const url = `${API}/properties/${propertyId}/jobs/${jobId}/trunks/${geohash}`
      const tileResponse = await authorizedFetch(url)
      const tileTrunks = parseTrunks(await tileResponse.arrayBuffer())
      trunks.push(...tileTrunks)
    }),
  )
  return trunks
}

export const getPointcloudGeohashes = async projectId => {
  if (!projectId) return undefined
  const url = `${API}/projects/${projectId}/pointcloud-geohashes`
  const response = await authorizedFetch(url)
  return response.json()
}

export const getTreeCorrections = async jobId => {
  if (!jobId) return undefined
  const url = `${API}/tree-corrections/${jobId}`
  const response = await authorizedFetch(url)
  return response.json()
}

export const createOmission = async (jobId, geohash, species, height, crownWidth) => {
  const url = `${API}/create-omission`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ jobId, geohash, species, height, crownWidth }),
  }
  return authorizedFetch(url, options)
}

export const createCommission = async (jobId, geohash) => {
  const url = `${API}/create-commission`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ jobId, geohash }),
  }
  return authorizedFetch(url, options)
}

export const deleteTreeCorrection = async (jobId, geohash) => {
  const url = `${API}/delete-tree-correction`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ jobId, geohash }),
  }
  return authorizedFetch(url, options)
}

export const getTreeCorrectionTiles = async jobId => {
  if (!jobId) return undefined
  const url = `${API}/tree-correction-tiles/${jobId}`
  const response = await authorizedFetch(url)
  return response.json()
}

export const updateTreeCorrectionTile = async (jobId, geohash, status) => {
  const url = `${API}/update-tree-correction-tile`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ jobId, geohash, status }),
  }
  return authorizedFetch(url, options)
}

export const getScanSlices = async projectId => {
  if (!projectId) return undefined
  const url = `${API}/projects/${projectId}/scan-slices`
  const response = await authorizedFetch(url)
  return response.json()
}

export const getEvaluationImages = async projectId => {
  if (!projectId) return undefined
  const url = `${API}/projects/${projectId}/evaluation-images`
  const response = await authorizedFetch(url)
  return response.json()
}

export const getPlannerUsers = async () => {
  const url = `${API}/planner-users`
  const response = await authorizedFetch(url)
  return response.json()
}

export const setPlannerUserDeveloper = async (email, value) => {
  const url = `${API}/planner-user-developer`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, value }),
  }
  return authorizedFetch(url, options)
}

export const setPlannerUserOrderbook = async (email, value) => {
  const url = `${API}/planner-user-orderbook`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, value }),
  }
  return authorizedFetch(url, options)
}

export const addPlannerUserProperties = async (email, propertyIds) => {
  const url = `${API}/add-planner-user-properties`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, propertyIds }),
  }
  return authorizedFetch(url, options)
}

export const removePlannerUserProperties = async (email, propertyIds) => {
  const url = `${API}/remove-planner-user-properties`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, propertyIds }),
  }
  return authorizedFetch(url, options)
}

export const grantPlanAccess = async (email, planIds) => {
  const url = `${API}/grant-plan-access`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, planIds }),
  }
  return authorizedFetch(url, options)
}

export const revokePlanAccess = async (email, planIds) => {
  const url = `${API}/revoke-plan-access`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, planIds }),
  }
  return authorizedFetch(url, options)
}

export const createCompartmentsDownload = async (projectId, compartmentVersionId) => {
  const url = `${API}/create-compartments-download`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ projectId, compartmentVersionId }),
  }
  const response = await authorizedFetch(url, options)
  if (!response.ok) {
    const { message } = await response.json()
    throw new Error(message)
  }
  return response.json()
}

export const createTreesDownload = async (projectId, algorithmPipelineJobId, source) => {
  const url = `${API}/create-trees-download`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ projectId, algorithmPipelineJobId, source }),
  }
  const response = await authorizedFetch(url, options)
  if (!response.ok) {
    const { message } = await response.json()
    throw new Error(message)
  }
  return response.json()
}

export const createParcelKmlDownload = async (propertyId, parcelNumber) => {
  const url = `${API}/properties/${propertyId}/parcel-kml-download/${parcelNumber}`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }
  const response = await authorizedFetch(url, options)
  if (!response.ok) {
    const { message } = await response.json()
    throw new Error(message)
  }
  return response.json()
}

export const createBackpackPlannerDownload = async projectId => {
  const url = `${API}/projects/${projectId}/backpack-planner-download`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }
  const response = await authorizedFetch(url, options)
  if (!response.ok) {
    const { message } = await response.json()
    throw new Error(message)
  }
  return response.json()
}

export const getBackpackPlannerDownload = async projectId => {
  const url = `${API}/projects/${projectId}/backpack-planner-download`
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }
  const response = await authorizedFetch(url, options)
  if (!response.ok) {
    const { message } = await response.json()
    throw new Error(message)
  }
  return response.json()
}
